import { subject } from "@casl/ability";

let role_moderator = ["chat-moderator-page","chat-list-word-blacklist-page","chat-new-word-blacklist-page","login"];

let menu = [
  // {
  //   title: 'Painel de controle',
  //   route: 'dashboard',
  //   icon: 'EyeIcon',
  // },
  {
    title: 'Rodadas',
    icon: 'PlayCircleIcon',
    children: [
      {
        title: 'Rodada em andamento',
        route: 'current-round',
        icon: 'PlayIcon',
      },
      {
        title: 'Próximas Rodadas',
        route: 'next-rounds',
        icon: 'FastForwardIcon',
        action: 'read',
        resource: 'rounds',
      },
      {
        title: 'Rodadas Anteriores',
        route: 'prev-rounds',
        icon: 'RewindIcon',
        action: 'read',
        resource: 'rounds',
      },
    ],
   
  },
  {
    title: 'Chat Moderator',
    icon: 'MessageCircleIcon',
  
    children: [
      {
        title: 'Chat Moderator',
        route: 'chat-moderator-page',
        icon: 'MessageCircleIcon',
        action: 'read',
        resource: 'moderable',
      },
      {
        title: 'Word BlackList',
        route: 'chat-list-word-blacklist-page',
        icon: 'FrownIcon',
        action: 'read',
        resource: 'moderable',
      },
      {
        title: 'New Word BlackList',
        route: 'chat-new-word-blacklist-page',
        icon: 'PlusCircleIcon',
        action: 'read',
        resource: 'moderable',
      },
    ],
  },
  
  {
    title: 'Enquete',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Enquete ativa',
        route: 'current-poll',
        icon: 'ListIcon',
      },
      {
        title: 'Nova Enquete',
        route: 'create-poll',
        icon: 'PlusCircleIcon',
      },
      {
        title: 'Listar enquetes',
        route: 'next-polls',
        icon: 'ListIcon',
      },
      {
        title: 'Enquetes anteriores',
        route: 'prev-polls',
        icon: 'ListIcon',
      },
    ],
  },
  {
    title: 'Horas Espec.',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Resultados',
        route: 'rush-hour',
        icon: 'ListIcon',
      },
    ],
  },
  // {
  //   title: 'Operações Master',
  //   icon: 'UserIcon',
  //   children: [
      
  //     {
  //       title: 'Meus clientes',
  //       route: 'master-users',
  //       icon: 'UserIcon',
  //       action: 'read',
  //       resource: 'master',
  //     },
  //     {
  //       title: 'Minhas Transações',
  //       route: 'master-transactions',
  //       icon: 'ListIcon',
  //       action: 'read',
  //       resource: 'master',
  //     },
  //   ],
  // },
  // {
  //   title: 'Clientes',
  //   route: 'users-list',
  //   icon: 'UserIcon',
  // },
  // {
  //   title: 'Cadastros BLar',
  //   route: 'plat-users',
  //   icon: 'UserIcon',
  //   action: 'read',
  //   resource: 'chat-attendant',
  // },
  
  // {
  //   title: 'Transações',
  //   icon: 'DollarSignIcon',
  //   children: [
  //     {
  //       title: 'Últimos depósitos confirmados',
  //       route: 'last-deposits',
  //       icon: 'ThumbsUpIcon',
  //       action: 'read',
  //       resource: 'chat-attendant',
  //     },    
  //     {
  //       title: 'Últimos saques confirmados',
  //       route: 'last-withdraws',
  //       icon: 'ThumbsDownIcon',
  //       action: 'read',
  //       resource: 'chat-attendant',
  //     }
  //   ],
  // },
  {
    title: 'Usuários',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Create CPanel Usuários ',
        route: 'create-user',
        icon: 'PlusCircleIcon',
      },    
      {
        title: 'CPanel Usuários ',
        route: 'users-page',
        icon: 'ListIcon',
      }
    ],
  },
  {
    title: 'Sair',
    route: 'login',
    icon: 'XCircleIcon',
    action: 'read',
    resource: 'Auth',
  },    
  // {
  //   title: 'Second Page',
  //   route: 'second-page',
  //   icon: 'FileIcon',
  // },
]

/*

let json = decodeURIComponent(escape(window.atob( window.localStorage.getItem('user') )));

console.log(json)
let u = JSON.parse( json)

if (u.role=="moderator")
{
  menu = menu.filter(x=> role_moderator.indexOf(x.route) !=-1)
}

*/

export default menu